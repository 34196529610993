import React, { Component } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import config from "../../config"
import ogImage from "../assets/img/intro/kart-on-ice.jpg"

class Layout extends Component {
  render() {
    const { children } = this.props
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                {
                  name: "description",
                  content: config.siteMetadata.description,
                },
                { name: "keywords", content: config.siteMetadata.keywords },
                { name: "og:title", content: config.siteMetadata.title },
                {
                  name: "og:description",
                  content: config.siteMetadata.description,
                },
                {
                  name: "og:image",
                  content: `${config.siteMetadata.url}${ogImage}`,
                },
                { name: "charset", content: "utf-8" },
                { name: "http-equiv", content: "IE=edge" },
                { name: "author", content: "intesso & AG Hallenstadion" },
                {
                  name: "viewport",
                  content: "width=device-width, initial-scale=1",
                },
              ]}
            >
              <html lang="de" />
            </Helmet>
            <div className={"page-top"}>{children}</div>
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
