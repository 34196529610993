module.exports = {
  siteMetadata: {
    title: "Kartrace on Ice - Where Game meets Reality",
    description:
      "Neuartiges Augmented-Reality-Erlebnis. Du bist mit Elektro-Driftkarts Teil eines interaktiven Computergames, in echt, in der Arena. Bisher nicht bekanntes Game Erlebnis für gemeinsames, intensives Gamen und Fahrspass pur. Kartrace on Ice bringt die virtuelle Welt in die Realität. Go-Karts, Drift Action, gegenseitige Interaktion kombiniert mit Computerspielen bringt Gamen im Raum auf ein neues Level. Dieses neue, einmalige Kart Game Angebot lässt die Herzen von Gross bis Klein höherschlagen! Überzeug dich selber und probier kartrace aus. Bist du bereit um in echt zu gamen?",
    keywords:
      "kartrace, kart, drift, kart game, go-karts, kartgames, game, fun, action, speed, events, Augmented Reality",
    url: "https://kartrace.ch",
  },
  siteTitle: "Kartrace on Ice - Where Game meets Reality",
  errorSiteTitle: "Uups, Seite nicht auffindbar",
  manifestName: "Kartrace on Ice",
  manifestShortName: "Kartrace on Ice", // max 12 characters
  manifestStartUrl: "/",
  manifestBackgroundColor: "#0095c0",
  manifestThemeColor: "#0095c0",
  manifestDisplay: "standalone",
  manifestIcon: "src/assets/img/favicon.png",
  trackingId: "G-NB49JBF7SC",
  pathPrefix: "", // This path is subpath of your hosting https://domain/portfolio

  // social
  socialLinks: [
    {
      icon: "fa-facebook-f",
      name: "Facebook",
      url: "https://www.facebook.com/hallenstadion.ch",
    },
    {
      icon: "fa-instagram",
      name: "Instagram",
      url: "https://www.instagram.com/hallenstadionzuerich/",
    },
    {
      icon: "fa-youtube",
      name: "Youtube",
      url: "https://www.youtube.com/channel/UCOMLbrnkeNm-OICEpr8GUSw",
    },
    {
      icon: "fa-linkedin",
      name: "LinkedIn",
      url: "https://www.linkedin.com/company/ag-hallenstadion-zurich/",
    },
  ],
  email: "contact@intesso.com",
  request: "http://intesso.com/projects/where-game-meets-reality",
  ticketing: "https://www.ticketcorner.ch/artist/kartrace/",
  restaurant: "https://www.letusentertainyou.ch/kart-on-ice/",
  restaurant2: "https://zumfrischenmax.ch/lageplan/",
  phone: "+41 44 316 77 77",
  address: "HALLENSTADION<br/>Wallisellenstrasse 45<br/>8050 Zürich",
  introTitleIndexBGWhite: "where game",
  introTitleIndexBGBlue: "meets reality",
  news1: "Der Betrieb ist eingestellt!",
  news2: "",
}
